import React from "react";
import { Container, Dropdown } from "react-bootstrap";
import "./header.css";
import {
  HeaderTwoLogo,
  Profile_Icon,
  Notification,
} from "../../../assets/images";
import { Link } from "react-router-dom";
import axios from "axios";
import { message } from "antd";
import apiConfig from "../../../config/api.config";
const HeaderTwo = (props) => {
  const logout = async () => {
    localStorage.clear();
    message.warning("You are being logged out. Please wait...");
    window.open("/logout","_self")
  };
  
  return (
    <div className="header_partttss">
      <Container fluid>
        <div className="header_inner">
          <div className="header_logo">
             <a href="/">
              <img src={HeaderTwoLogo} alt="" />
            </a>
          </div>
          <div className="header_user">
            <Dropdown className="noti_drop">
              <Dropdown.Toggle id="dropdown-basic" className="dropdown_noti">
                <img src={Notification} />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="#">
                  Nothing to show right now
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <span>Welcome, {props.userDetails&&props.userDetails.name}!</span>
            <div className="prf_iconnn">
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic" className="dropdown_noti">
                  <img src={Profile_Icon} alt="" />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    href="#/action-1"
                    onClick={() => {
                      logout();
                    }}
                  >
                    Log Out
                  </Dropdown.Item>
                  {/* <Dropdown.Item href="#/action-2">My Account</Dropdown.Item> */}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            {/* <Link to="#" className='ml-1' onClick={() => {logout()}}  > Logout</Link> */}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default HeaderTwo;
