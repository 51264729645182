import React, { useState, useEffect } from "react";
//import "./styles.css";
import { Container, Modal, Form, Button } from "react-bootstrap";

import "./plan.css";
import AdditionalInfo from "./additional-info";
import useFormValidation from "../../hooks/useFormValidator";
import axios from "axios";
import { signUp } from "../../axios/signUp.axios";
import { useSelector, useDispatch } from "react-redux";
import { setTempUser } from "../../actions/signUp.actions";
import { message } from "antd";
import { NotificationContainer } from "react-notifications";

const SignUp = (props) => {
  // const [show, setShow] = useState(false);
  const dispatch = useDispatch();

  const code = useSelector(state => state.changeStep.affiliate)

  const [input, setInput] = useState({
    email: "",
    password: "",
    affiliate: ""
  });

  const [validationReport, validate] = useFormValidation();
  const [buttonStatus, setButtonStatus] = useState(true);

  const change = async (wizard) => {
    if (
      validationReport.email &&
      validationReport.email.isValid &&
      validationReport.password &&
      validationReport.password.isValid
    ) {
      props.loader(true);
      await signUp(input)
        .then((response) => {
          console.log(response, "res");
          dispatch(setTempUser(response.data.data));
          props.changeWizard(wizard);
          props.loader(false);
        })
        .catch((err) => {
          props.loader(false);
        });
    } else {
    }
  };

  const handleChange = (event) => {
    let rules = JSON.parse(event.target.dataset.rules);

    validate(event.target.value, rules, event.target.name);
    setInput({ ...input, [event.target.name]: event.target.value });

    if (event.target.name == 'email') {
      setInput({ ...input, [event.target.name]: event.target.value.toLowerCase() });
    } else {
      setInput({ ...input, [event.target.name]: event.target.value });
    }
  };

  function log() {
    console.log("report", validationReport);
  }


  useEffect(() => {
    if (code)
      setInput({
        affiliate: code
      })
  }, code)

  return (
    <div className="sign_up_partts">
      <NotificationContainer />
      <h3>1. Sign Up</h3>
      <p>
        To purchase this plan and use its benefits in the future, log in to your
        account or sign up.
      </p>

      <Form>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            value={input.email}
            type="email"
            name="email"
            placeholder="Enter email"
            className="sign_email"
            data-rules={JSON.stringify({
              required: true,
              email: true,
            })}
            onChange={(event) => {
              handleChange(event);
            }}
          />
          {validationReport.email && !validationReport.email.isValid ? (
            <small>{validationReport.email.errorMessage} </small>
          ) : (
            ""
          )}
          {log()}
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            value={input.password}
            name="password"
            type="password"
            placeholder="Password"
            className="sign_email"
            data-rules={JSON.stringify({
              required: true,
              password: true
            })}
            onChange={(event) => {
              handleChange(event);
            }}
          />
          {validationReport.password && !validationReport.password.isValid ? (
            <small>{validationReport.password.errorMessage} </small>
          ) : (
            ""
          )}
        </Form.Group>

        <div className="sign_submit flex-wrap justify-content-between align-items-center">
          <p className="mb-0">
            Already have an account? <a href="/login">Login</a>
          </p>
          {/* {show && <AdditionalInfo />} */}
          <Button
            variant="primary"
            type="button"
            onClick={() => {
              change("aInfo");
            }}
            disabled={!buttonStatus}
          >
            Next
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default SignUp;
