import React from "react";
import { FooterLogo } from "../../assets/images";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./planfooter.css";

export default function PlanFooter() {
  return (
    <section className="footer">
      <Container>
        <div className="inner_footer">
          <figure>
            <img src={FooterLogo} />
          </figure>
          <p>
            Copyright 2023-TripValet
            <span>All Rights Reserved</span>
          </p>
          <ul>
            <li>
              <Link to="#">Privacy Policy</Link>
            </li>
            <li>
              <Link to="#">Terms & Conditions</Link>
            </li>
          </ul>
        </div>
      </Container>
    </section>
  );
}
