import React from "react";
import { Container } from "react-bootstrap";
import ReactPlayer from "react-player";
import CheckoutPart from "../../pages/signup/checkout";
import { HeaderTwoLogo } from "../../assets/images";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { payments } from "../../actions/payment.actions";
import { getPrice } from "../../axios/payment.axios";
import { NotificationManager, NotificationContainer } from 'react-notifications';
import './new-update-plan.css';


const UpdatePlanLoveTravels = (props) => {


    const dispatch = useDispatch()
    const history = useHistory()

    const setProduct = async (event) => {

        event.preventDefault()
        let price_id = event.target.dataset.price
        getPrice(price_id)
            .then(response => {
                let name = event.target.dataset.name
                let product_id = event.target.dataset.name

                let product = {
                    product_id,
                    name,
                    price: response.data,
                    price_id,
                    initialPrice: response.data,
                    priceObject: response.metadata
                }
                //   console.log(product,"product")
                dispatch(payments.setProduct(product))
                //   window.open("/check-out")

                history.push("/update-plan-payment")
            })
            .catch(err => {
                NotificationManager.error("Something went wrong")
            })




    }

    return (
        <div className="monthly_price">
            <div className="price_inner">
                <div className="price_inner_inner">
                    <div className="price_inner_heading">
                        <h2>Yearly</h2>
                        <h3>$347</h3>
                    </div>
                    <div className="price_button">
                        {(props.currentPackage == 347) ? (<>
                            <button type="button" className="get_started_but" >
                                Current Package
                            </button>
                        </>) : (<>
                            <button type="button" className="get_started_but" onClick={(event) => { setProduct(event) }} data-price={props.products.tv[0].price} data-product_id={props.products.tv[0].id} data-name={props.products.tv[0].name}>
                                Get Plan
                            </button>
                        </>)}
                    </div>
                </div>
            </div>
            <div className="price_inner">
                <div className="price_inner_inner">
                    <div className="price_inner_heading">
                        <h2>Monthly</h2>
                        <h3>$49.97</h3>
                    </div>
                    <div className="price_button">
                        {(props.currentPackage == 49.97) ? (<>
                            <button type="button" className="get_started_but" >
                                Current Package
                            </button>
                        </>) : (<>
                            <button type="button" className="get_started_but" onClick={(event) => { setProduct(event) }} data-price={props.products.tv[1].price} data-product_id={props.products.tv[1].id} data-name={props.products.tv[1].name}>
                                Get Plan
                            </button>
                        </>)}

                    </div>
                </div>
            </div>
        </div>

    );
};
export default UpdatePlanLoveTravels;
