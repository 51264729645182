import axios from "./axios";
const source = "tv"
export const getPrice = (price_id) => {
    return new Promise((resolve,reject)=>{
        axios.post("/user/get-price",{price_id,source})
        .then((response)=>{
            resolve(response.data)
        })
        .catch((err)=>{
            reject(err.response.data.message)
        })
    })
}
export const getTokenDetails = (coupon) => {
    return new Promise((resolve,reject)=>{
        axios.post("/user/get-coupon-verified",{coupon,source})
        .then((response)=>{
            resolve(response.data.data)
        })
        .catch((err)=>{
            reject(err.response.data.message)
        })
    })
}