import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import ReactPlayer from "react-player";
import "./travel.css";
import { Link, useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCode } from "../../actions/signUp.actions";
import { increaseHits } from "../../axios/signUp.axios";
import testimonialImg from '../../assets/images/testimonial_last.png';
import flightImg from '../../assets/images/flight_image.jpg';
import {
  LogoTrip,
  PosterImage,
  Image1,
  Image2,
  Image3,
  Image4,
  Image5,
  Image6,
  Loves1,
  Loves2,
  Loves3,
  Loves4,
  Loves5,
  Loves6,
  Loves8,
  Bulb1,
  Bulb2,
  Bulb3,
  Bulb4,
  Bulb5,
} from "../../assets/images";
import { products } from "../../products/products.details";
import { getPrice } from "../../axios/payment.axios";
import { payments } from "../../actions/payment.actions";
import { NotificationManager } from "react-notifications";

const PlanPart = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const [affCode, SetaffCode] = useState("");
  let timer;
  const callIncreaseHits = () => {
    if (timer) clearTimeout(timer);
    timer = () =>
      setTimeout(() => {
        // increaseHits(params.code)
        increaseHits(params.code, params.stype)
          .then((res) => {
            SetaffCode(res.data.code_by);
          })
          .catch((err) => { });
      }, 500);
    timer();
  };

  useEffect(() => {
    if (!timer) {
      dispatch(setCode(affCode));
      if (params.code) callIncreaseHits();
    }
  }, []);

  useEffect(() => {
    dispatch(setCode(affCode));
  }, [affCode]);

  const setProduct = (product) => {
    let price_id = product.price;

    getPrice(price_id)
      .then((response) => {
        let name = product.name;
        let product_id = product.id;

        let product_details = {
          product_id,
          name,
          price: response.data,
          price_id,
          initialPrice: response.data,
          priceObject: response.metadata,
        };
        dispatch(payments.setProduct(product_details));

        history.push("/check-out");
      })
      .catch((err) => {
        console.error(err);
        NotificationManager.error("Something went wrong");
      });
  };
  //const history = useHistory();

  useEffect(() => {
    const location = history.location;
    if (location.hash) {
      const targetElement = document.querySelector(location.hash);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [history.location]);
  return (
    <section className="home_pqage">
      <div className="home_inner_banner">
        <div className="inner_banner_text">
          <h1>TRAVEL MORE. WORRY LESS.</h1>
          <h2>
            <span>Save Up To 70%</span> On All Your Travel
          </h2>
          <div className="free_trial_but">
            <Link to="#trial">Start Your Free Trial!</Link>
          </div>

          <div className="logo_trip">
            <img alt="TV" src={LogoTrip} width="400" />
          </div>
        </div>
      </div>
      <div className="discover_tripvalet">
        <Container>
          <div className="discover_inner">
            <div className="discover_inner_text">
              <h2>Discover TripValet</h2>
              <p>
                TripValet is a VIP Members Only travel membership giving members
                exclusive access to the world’s greatest travel deals for
                flights, hotels, resorts, car rental, cruises and activities
                while also giving Members the ability to earn free travel with
                every reservation.
              </p>
            </div>

            <ReactPlayer
              url="https://player.vimeo.com/video/281844822?app_id=122963&autoplay=1&controls=1"
              light={PosterImage}
              playing
              width="100%"
              controls
            />
          </div>
        </Container>
      </div>

      <div className="where_you_will_go">
        <Container>
          <div className="where_go_inner">
            <h3>Where Will You Go Next?</h3>
            <div className="where_go_image_box">
              <div className="where_box_image">
                <img alt="TV" src={Image1} className="img-fluid" />
              </div>

              <div className="where_box_image">
                <img alt="TV" src={flightImg} className="img-fluid" />
              </div>
              <div className="where_box_image">
                <img alt="TV" src={Image3} className="img-fluid" />
              </div>
              <div className="where_box_image">
                <img alt="TV" src={Image4} className="img-fluid" />
              </div>
              <div className="where_box_image">
                <img alt="TV" src={Image5} className="img-fluid" />
              </div>
              <div className="where_box_image">
                <img alt="TV" src={Image6} className="img-fluid" />
              </div>
            </div>
          </div>
          <div className="total_inner_member">
            <div className="inner_mem">
              Total Member Savings: Over $115,000,000 USD
            </div>
          </div>
          <div className="top_benefits">
            <h3>Top Benefits:</h3>
            <h4>*1,000,000+ Properties</h4>
            <p>Save on all top hotel and resort brands anywhere in the world</p>

            <h4>*Reward Credits</h4>
            <p>
              Earn Reward Credits on every purchase you make. Save up your
              reward credits and use to book future travel
            </p>

            <h4>*Price Match Guarantee</h4>
            <p>
              If you find a hotel cheaper online, we promise to credit you 110%
              of the difference
            </p>
          </div>
        </Container>
      </div>
      <div className="loves_travel1">
        <Container>
          <div className="loves_travel_inner">
            <h2>Everyone Loves To Travel!</h2>
            <div className="lovees_travel_inner_box">
              <div className="loves_inner">
                <img alt="TV" src={Loves1} className="img-fluid" />
              </div>
              <div className="loves_inner">
                <img alt="TV" src={Loves2} className="img-fluid" />
              </div>
              <div className="loves_inner">
                <img alt="TV" src={Loves3} className="img-fluid" />
              </div>
              <div className="loves_inner">
                <img alt="TV" src={Loves4} className="img-fluid" />
              </div>
              <div className="loves_inner">
                <img alt="TV" src={Loves5} className="img-fluid" />
              </div>
              <div className="loves_inner">
                <img alt="TV" src={Loves6} className="img-fluid" />
              </div>
              <div className="loves_inner">
                <img alt="TV" src={Loves8} className="img-fluid" />
              </div>
              <div className="loves_inner">
                <img alt="TV" src={testimonialImg} className="img-fluid" />
              </div>
            </div>
            <div className="free_trial_but">
              <Link
                to="#trial"
                style={{ backgroundColor: "white", color: "rgb(16, 127, 170)" }}
              >
                Start Your Free Trial!
              </Link>
            </div>
          </div>
        </Container>
      </div>

      <div className="trip_member">
        <Container>
          <div className="trip_mem_inner">
            <div className="include_trip_mem">
              <h2>What's Included In a TripValet Membership?</h2>
              <p>
                This is an online subscription-based VIP Membership with a 7-Day
                Free Trial. Absolutely NO Contracts or Setup Fees.
              </p>
              <label>Cancel Anytime.</label>
              <div className="mem_items">
                <ul>
                  <li>
                    Friends & Family Plan: You + 9 others can save using the
                    same membership!
                  </li>

                  <li>Exclusive Members Only Booking Portal</li>

                  <li>110% Price Guarantee on Hotel Bookings</li>

                  <li>Over 1,000,000 hotels & resorts to choose from</li>

                  <li>Over 500,000 Resort Weeks</li>

                  <li>40,000+ Car Rental Locations</li>

                  <li>45,000+ Activity Options</li>

                  <li>2200+ Golf Courses</li>

                  <li>15,000+ Cruise Sailings</li>

                  <li>Over 7 Million Event Tickets at 18,000+ Venues</li>

                  <li>
                    10,000+ Movie Theater Locations (Tickets discounted up to
                    49%)
                  </li>

                  <li>
                    Earn Reward Credits on your bookings to use towards future
                    travel
                  </li>

                  <li>
                    Benefit Hub Gives Access To Unique Shopping And
                    Entertainment Offers
                  </li>

                  <li>
                    Substantial discounts on hotels, cruises, condos, excursions
                    and airfare
                  </li>

                  <li>
                    Private, curated VIP trips with a host for an unforgettable
                    vacation
                  </li>

                  <li>Low Annual Membership or pay monthly</li>
                </ul>
              </div>
              <div className="free_trial_but">
                <Link to="#trial">Start Your Free Trial!</Link>
              </div>
              <div className="text-center">
                <h3>Select Your Membership Savings</h3>
              </div>
            </div>
          </div>

          <div className="monthly_annually" id="trial">
            <div className="monthly_doller">
              <div className="inner_month_dollar">
                {/* <h4>Monthly</h4> */}
                <h4>{products.tv[1].name}</h4>
                <label>$49.97</label>
                <p>
                  Renews every month
                  <label>VIP Travel Membership with up to 70% savings</label>
                </p>
                <div className="day_free_trial">
                  <div
                    onClick={() => {
                      setProduct(products.tv[1]);
                    }}
                  >
                    Start Your 7 Day Free Trial
                  </div>
                </div>
              </div>
            </div>
            <div className="monthly_doller">
              <div className="inner_month_dollar">
                {/* <h4>Annual</h4> */}
                <h4>{products.tv[0].name}</h4>
                <label>$347</label>
                <p>
                  Renews every year
                  <label>Save 41% with no monthly membership fees</label>
                </p>
                <div className="day_free_trial">
                  <div
                    onClick={() => {
                      setProduct(products.tv[0]);
                    }}
                  >
                    Start Your 7 Day Free Trial
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="notify_member">
            <p>
              Members will be notified 3 days before plan is due to auto renew.
              Due to the electronic nature of the membership, we can not issue
              refunds if the membership is not cancelled before renewal.
            </p>
          </div>
        </Container>
      </div>

      <div className="faq_partts">
        <Container>
          <div className="faq_inner_partts">
            <div className="faq_inner_heading">
              <h2>FAQ's</h2>
            </div>
            <div className="faq_item">
              <h3>How do I cancel my 7 Day Trial before I get charged?</h3>
              <p>
                <strong>7 DAY CANCELLATION POLICY:</strong> All cancellations
                must be received electronically on or before the sixth day while
                on the seven day trial. The credit card used to enroll will be
                charged on day seven. To cancel a membership, send an email with
                member's name and reason for cancellation to
                support@tripvalet.com.{" "}
                <strong>
                  "DUE TO THE NATURE OF OUR MEMBERSHIP NO REFUNDS ON THE
                  MEMBERSHIP ARE AVAILABLE AFTER THE TRIAL PERIOD."
                </strong>
              </p>
            </div>

            <div className="faq_item">
              <figure>
                <img alt="TV" src={Bulb1} />
              </figure>
              <h3>How much can I save using TripValet?</h3>
              <p>
                Our members save an average of 50-80% per night on hotel
                bookings through the TripValet travel portal. Members are saving
                a significant amount of dollars on car rentals, cruises, and
                excursions as well. Sometimes you’ll get lucky and find a great
                deal on flights too!
              </p>
            </div>

            <div className="faq_item">
              <figure>
                <img alt="TV" src={Bulb2} />
              </figure>
              <h3>How do I earn Reward Credits?</h3>
              <p>
                Reward Credits are earned for all bookings on your member
                account. Credits will post to the members account once the
                reservation has been completed. Members have the ability to use
                points or a credit card to pay for future bookings.
              </p>
            </div>

            <div className="faq_item">
              <figure>
                <img alt="TV" src={Bulb3} />
              </figure>
              <h3>Are there any extra fees to book travel on the site?</h3>
              <p>
                NOPE! TripValet does not charge any kind of service fees for
                booking on our platform.
              </p>
            </div>

            <div className="faq_item">
              <figure>
                <img alt="TV" src={Bulb4} />
              </figure>
              <h3>
                Is the membership available to those outside the United States?
              </h3>
              <p>
                YES! Our membership travel portal is available in multiple
                different countries. The websites are translated into many
                languages including but not limited to Spanish, French, Persian,
                German, Russian, Portuguese, Italian, Turkish, Korean and more.
              </p>
            </div>

            <div className="faq_item">
              <figure>
                <img alt="TV" src={Bulb5} />
              </figure>
              <h3>Is there an Affiliate program?</h3>
              <p>
                Yes! We love it when our members share it with others and we
                will generously pay you to do it! Upon your enrollment, you'll
                automatically be given unique affiliate links. When you share
                those links, and someone enrolls, we pay you! It's that simple!!
                Affiliates need to be able to receive payouts from PayPal and
                accept commissions in USD. Affiliates that make over $600
                annually will be subject to a 1099.
              </p>
            </div>

            <div className="ready_get">
              <Link to="#trial">Yes! I'm Ready To Get Started!</Link>
            </div>
          </div>
        </Container>
      </div>
    </section>
  );
};

export default PlanPart;
