import React from "react";
//import "./styles.css";

import { Row, Col, Button, Modal, Form } from "react-bootstrap";
import Leftpanel from "../../components/leftpanel/leftpanel";
import HeaderPartTwo from "../../components/layout/header/header2";
import "./dashboard.css";
import {
  Banner_1,
  Shopping,
  MyAccntBanner,
  ReferFriend,
} from "../../assets/images";
import { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { message } from "antd";
import apiConfig from "../../config/api.config";
import LoadingOverlay from "react-loading-overlay";

const DashboardPart = (props) => {
  const [user, setUser] = useState(
    localStorage.getItem("userDetails")
      ? JSON.parse(localStorage.getItem("userDetails"))
      : null
  );
  const [arriviaUserId, setArriviaUserId] = useState(
    user ? user.arriviaUserId : null
  );
  const [startLogin, setStartLogin] = useState(false);
  const [show, setShow] = useState(false);
  const [first, setFirst] = useState("");
  const [phone, setPhone] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [last, setLast] = useState("");
  const [address, setAddress] = useState("");
  const [loadTravel, setLoadTravel] = useState("");
  const modalClose = () => {
    setLoadTravel(false);
    setShow(false);
  };

  const handleFirst = (event) => {
    switch (event.target.name) {
      case "first":
        setFirst(event.target.value);
        break;
      case "last":
        setLast(event.target.value);
        break;
      case "first":
        setFirst(event.target.value);
        break;
      case "address":
        setAddress(event.target.value);
        break;
      case "city":
        setCity(event.target.value);
        break;
      case "phone":
        setPhone(event.target.value);
        break;

      default:
        setCountry(event.target.value);
        break;
    }
  };
  const modalShow = () => setShow(true);
  const arriviaRegister = async () => {
    let isRegistered = false;
    if (!first || !phone || !city || !country || !last || !address) {
      console.log(first);
      message.warning("Please fill up the form properly");
    } else {
      modalClose();
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
        },
      };
      message.warning("You are being redirected, please wait");
      let data = {
        firstName: first,
        lastName: last,
        phone,
        country,
        city,
        address,
      };
      await axios
        .post(`${apiConfig.api}user/arrivia_register`, data, config)
        .then((res) => {
          if (res.data.status) {
            setArriviaUserId(res.data.data);
            setStartLogin(true);
            let userDetails = JSON.parse(localStorage.getItem("userDetails"));
            userDetails.arriviaUserId = res.data.data;
            console.log(userDetails, "us");
            localStorage.setItem("userDetails", JSON.stringify(userDetails));
            isRegistered = true;
            //window.open(apiConfig.arriviaRedirectUrl + res.data.data, "_blank")
          } else {
            message.error("Something went wrong. Login failed!");
          }
        })
        .catch((err) => {
          console.log("err", err);
          message.error(
            err.response.data.message ??
              "Something went wrong. Please try again letter"
          );
        });

      if (isRegistered) {
        message.success("User is successfully registered.");
      } else {
      }
    }
  };
  useEffect(() => {
    if (!localStorage.getItem("AuthToken")) {
      var token = localStorage.getItem("AuthToken");
      // setToken(token)
      window.open("/login", "_self");
    }
  }, []);

  useEffect(() => {
    if (arriviaUserId && startLogin) arriviaLogin();
  }, [arriviaUserId]);

  const arriviaLogin = async () => {
    setLoadTravel(true);
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem("AuthToken")}` },
    };
    if (!arriviaUserId) {
      modalShow();
      return 0;
    } else message.success("Redirecting..., please wait");
    await axios
      .post(`${apiConfig.api}user/arrivia_login`, {}, config)
      .then((res) => {
        if (res.data.status) {
          setLoadTravel(false);
          // message.success("Arrivia login successfull. Redirecting to dashboard. Please wait...");
          window.open(apiConfig.arriviaRedirectUrl + res.data.data, "_blank");
        } else {
          message.error("Something went wrong. Login failed!");
        }
      })
      .catch((err) => {
        console.log("err", err);
        setLoadTravel(false);
        message.error(
          err.response.data.message ??
            "Something went wrong. Please try again letter"
        );
      });
  };

  return (
    <div>
      <HeaderPartTwo userDetails={user} />
      <div className="main_cont">
        <Leftpanel
          arriviaLogin={() => {
            arriviaLogin();
          }}
        />
        <div className="right_cont">
          <div className="right_banner_banner_cnt">
            <Row>
              <Col lg="6" md="6" sm="12" className="right_innrBanner">
                <LoadingOverlay active={loadTravel} spinner text="Loading ...">
                  <div className="right_inner_inner_banner">
                    <a
                      href="#"
                      className="banner_box"
                      onClick={() => {
                        arriviaLogin();
                      }}
                    >
                      <img src={Banner_1} className="img-fluid" alt="" />
                      <div className="banner_overlay">Travel Deals</div>
                    </a>
                  </div>
                </LoadingOverlay>
              </Col>

              <Col lg="6" md="6" sm="12" className="right_innrBanner">
                <div className="right_inner_inner_banner">
                  <a
                    href={`${
                      process.env.REACT_APP_ENV === "PRODUCTION"
                        ? "https://tapi.tripvaletincentives.com/user/benifithub"
                        : "https://apistaging.tripvalet.com/user/benifithub"
                    }`}
                    className="banner_box"
                  >
                    <img src={Shopping} className="img-fluid" alt="" />
                    <div className="banner_overlay">
                      Shopping & Entertainment
                    </div>
                  </a>
                </div>
              </Col>

              <Col lg="6" md="6" sm="12" className="right_innrBanner">
                <div className="right_inner_inner_banner">
                  <a
                    href="#"
                    className="banner_box"
                    onClick={() => {
                      window.open(
                        "https://www.tripvalet.com/account/my-account"
                      );
                    }}
                  >
                    <img src={MyAccntBanner} className="img-fluid" alt="" />
                    <div className="banner_overlay">My Account</div>
                  </a>
                </div>
              </Col>

              <Col lg="6" md="6" sm="12" className="right_innrBanner">
                <div className="right_inner_inner_banner">
                  <a
                    href="#"
                    className="banner_box"
                    onClick={() => {
                      window.open("https://www.tripvalet.com/refer-others");
                    }}
                  >
                    <img src={ReferFriend} className="img-fluid" alt="" />
                    <div className="banner_overlay">Refer a friend</div>
                  </a>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>

      <Modal show={show}>
        <Modal.Body>
          <div className="popuptext">
            <Button
              variant="secondary"
              className="close_button"
              onClick={modalClose}
            ></Button>
            <h3>Title</h3>
            <div className="modal_form_group">
              <Form>
                <Row>
                  <Col lg="6" md="6" sm="12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter first name"
                        name="first"
                        className="modal_t_field"
                        value={first}
                        onChange={handleFirst}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="6" md="6" sm="12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter last name"
                        className="modal_t_field "
                        name="last"
                        value={last}
                        onChange={handleFirst}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Phone Number"
                    className="modal_t_field"
                    name="phone"
                    value={phone}
                    onChange={handleFirst}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    className="modal_t_area"
                    placeholder="Enter Address"
                    name="address"
                    value={address}
                    onChange={handleFirst}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter City"
                    className="modal_t_field"
                    name="city"
                    value={city}
                    onChange={handleFirst}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Country</Form.Label>
                  <Form.Control
                    as="select"
                    className="modal_t_field"
                    name="country"
                    value={country}
                    onChange={handleFirst}
                  >
                    <option value="00">Select country</option>
                    <option value="AD">Andorra</option>
                    <option value="AE">United Arab Emirates</option>
                    <option value="AF">Afghanistan</option>
                    <option value="AG">Antigua and Barbuda</option>
                    <option value="AI">Anguilla</option>
                    <option value="AL">Albania</option>
                    <option value="AM">Armenia</option>
                    <option value="AN">Netherlands Antilles</option>
                    <option value="AO">Angola</option>
                    <option value="AQ">Antarctica</option>
                    <option value="AR">Argentina</option>
                    <option value="AS">American Samoa</option>
                    <option value="AT">Austria</option>
                    <option value="AU">Australia</option>
                    <option value="AW">Aruba</option>
                    <option value="AX">Aland Islands</option>
                    <option value="AZ">Azerbaijan</option>
                    <option value="BA">Bosnia and Herzegovina</option>
                    <option value="BB">Barbados</option>
                    <option value="BD">Bangladesh</option>
                    <option value="BE">Belgium</option>
                    <option value="BF">Burkina Faso</option>
                    <option value="BG">Bulgaria</option>
                    <option value="BH">Bahrain</option>
                    <option value="BI">Burundi</option>
                    <option value="BJ">Benin</option>
                    <option value="BL">Saint Barthelemy</option>
                    <option value="BM">Bermuda</option>
                    <option value="BN">Brunei Darussalam</option>
                    <option value="BO">Bolivia</option>
                    <option value="BQ">Bonaire</option>
                    <option value="BR">Brazil</option>
                    <option value="BS">Bahamas</option>
                    <option value="BT">Bhutan</option>
                    <option value="BV">Bouvet Island</option>
                    <option value="BW">Botswana</option>
                    <option value="BY">Belarus</option>
                    <option value="BZ">Belize</option>
                    <option value="CA">Canada</option>
                    <option value="CC">Cocos (Keeling) Islands</option>
                    <option value="CD">Democratic Republic of the Congo</option>
                    <option value="CF">Central African Republic</option>
                    <option value="CG">Congo</option>
                    <option value="CH">Switzerland</option>
                    <option value="CK">Cook Islands</option>
                    <option value="CL">Chile</option>
                    <option value="CM">Cameroon</option>
                    <option value="CN">China</option>
                    <option value="CO">Colombia</option>
                    <option value="CR">Costa Rica</option>
                    <option value="CU">Cuba</option>
                    <option value="CV">Cape Verde</option>
                    <option value="CX">Christmas Island</option>
                    <option value="CY">Cyprus</option>
                    <option value="CZ">Czech Republic</option>
                    <option value="DE">Germany</option>
                    <option value="DJ">Djibouti</option>
                    <option value="DK">Denmark</option>
                    <option value="DM">Dominica</option>
                    <option value="DO">Dominican Republic</option>
                    <option value="DZ">Algeria</option>
                    <option value="EC">Ecuador</option>
                    <option value="EE">Estonia</option>
                    <option value="EG">Egypt</option>
                    <option value="EH">Western Sahara</option>
                    <option value="ER">Eritrea</option>
                    <option value="ET">Ethiopia</option>
                    <option value="ET">Ethiopia</option>
                    <option value="FI">Finland</option>
                    <option value="FJ">Fiji</option>
                    <option value="FK">Falkland Islands (Malvinas)</option>
                    <option value="FM">Federated States of Micronesia</option>
                    <option value="FO">Faroe Islands</option>
                    <option value="FR">France</option>
                    <option value="FX">France, Metropolitan</option>
                    <option value="GA">Gabon</option>
                    <option value="GB">Great Britain (UK)</option>
                    <option value="GD">Grenada</option>
                    <option value="GE">Georgia</option>
                    <option value="GF">French Guiana</option>
                    <option value="GH">Ghana</option>
                    <option value="GI">Gibraltar</option>
                    <option value="GL">Greenland</option>
                    <option value="GM">Gambia</option>
                    <option value="GN">Guinea</option>
                    <option value="GP">Guadeloupe</option>
                    <option value="GQ">Equatorial Guinea</option>
                    <option value="GR">Greece</option>
                    <option value="GS">
                      S. Georgia and S. Sandwich Islands{" "}
                    </option>
                    <option value="GT">Guatemala</option>
                    <option value="GU">Guam</option>
                    <option value="GW">Guinea-Bissau</option>
                    <option value="GY">Guyana</option>
                    <option value="HK">Hong Kong</option>
                    <option value="HM">
                      Heard Island and McDonald Islands
                    </option>
                    <option value="HN">Honduras</option>
                    <option value="HR">Croatia (Hrvatska)</option>
                    <option value="HT">Haiti</option>
                    <option value="HU">Hungary</option>
                    <option value="ID">Indonesia</option>
                    <option value="IE">Ireland</option>
                    <option value="IL">Israel</option>
                    <option value="IN">India</option>
                    <option value="IO">British Indian Ocean Territory</option>
                    <option value="IQ">Iraq</option>
                    <option value="IR">Iran</option>
                    <option value="IS">Iceland</option>
                    <option value="IT">Italy</option>
                    <option value="JM">Jamaica</option>
                    <option value="JO">Jordan</option>
                    <option value="JP">Japan</option>
                    <option value="KE">Kenya</option>
                    <option value="KG">Kyrgyzstan</option>
                    <option value="KH">Cambodia</option>
                    <option value="KI">Kiribati</option>
                    <option value="KM">Comoros</option>
                    <option value="KN">Saint Kitts and Nevis</option>
                    <option value="KP">Korea (North)</option>
                    <option value="KR">Korea (South)</option>
                    <option value="KW">Kuwait</option>
                    <option value="KY">Cayman Islands</option>
                    <option value="KZ">Kazakhstan</option>
                    <option value="LA">Laos</option>
                    <option value="LB">Lebanon</option>
                    <option value="LC">Saint Lucia</option>
                    <option value="LI">Liechtenstein</option>
                    <option value="LK">Sri Lanka</option>
                    <option value="LR">Liberia</option>
                    <option value="LS">Lesotho</option>
                    <option value="LT">Lithuania</option>
                    <option value="LU">Luxembourg</option>
                    <option value="LV">Latvia</option>
                    <option value="LY">Libya</option>
                    <option value="MA">Morocco</option>
                    <option value="MC">Monaco</option>
                    <option value="MD">Moldova</option>
                    <option value="ME">Montenegro</option>
                    <option value="MG">Madagascar</option>
                    <option value="MH">Marshall Islands</option>
                    <option value="MK">Macedonia</option>
                    <option value="ML">Mali</option>
                    <option value="MM">Myanmar</option>
                    <option value="MN">Mongolia</option>
                    <option value="MO">Macao</option>
                    <option value="MP">Northern Mariana Islands</option>
                    <option value="MQ">Martinique</option>
                    <option value="MR">Mauritania</option>
                    <option value="MS">Montserrat</option>
                    <option value="MT">Malta</option>
                    <option value="MU">Mauritius</option>
                    <option value="MV">Maldives</option>
                    <option value="MW">Malawi</option>
                    <option value="MX">Mexico</option>
                    <option value="MY">Malaysia</option>
                    <option value="MZ">Mozambique</option>
                    <option value="NA">Namibia</option>
                    <option value="NC">New Caledonia</option>
                    <option value="NE">Niger</option>
                    <option value="NF">Norfolk Island</option>
                    <option value="NG">Nigeria</option>
                    <option value="NI">Nicaragua</option>
                    <option value="NL">Netherlands</option>
                    <option value="NO">Norway</option>
                    <option value="NP">Nepal</option>
                    <option value="NR">Nauru</option>
                    <option value="NU">Niue</option>
                    <option value="NZ">New Zealand (Aotearoa)</option>
                    <option value="OM">Oman</option>
                    <option value="PA">Panama</option>
                    <option value="PE">Peru</option>
                    <option value="PF">French Polynesia</option>
                    <option value="PG">Papua New Guinea</option>
                    <option value="PK">Pakistan</option>
                    <option value="PL">Poland</option>
                    <option value="PN">Pitcairn</option>
                    <option value="PR">Puerto Rico</option>
                    <option value="PS">Palestinian Territory</option>
                    <option value="PT">Portugal</option>
                    <option value="PW">Palau</option>
                    <option value="PY">Paraguay</option>
                    <option value="QA">Qatar</option>
                    <option value="RE">Reunion</option>
                    <option value="RO">Romania</option>
                    <option value="RS">Serbia</option>
                    <option value="RU">Russian Federation</option>
                    <option value="RW">Rwanda arrivia Partner API Suite</option>
                    <option value="SA">Saudi Arabia</option>
                    <option value="SB">Solomon Islands</option>
                    <option value="SC">Seychelles</option>
                    <option value="SD">Sudan</option>
                    <option value="SE">Sweden</option>
                    <option value="SG">Singapore</option>
                    <option value="SH">Saint Helena</option>
                    <option value="SI">Slovenia</option>
                    <option value="SK">Slovakia</option>
                    <option value="SL">Sierra Leone</option>
                    <option value="SM">San Marino</option>
                    <option value="SN">Senegal</option>
                    <option value="SO">Somalia</option>
                    <option value="SR">Suriname</option>
                    <option value="ST">Sao Tome and Principe</option>
                    <option value="SU">USSR (former)</option>
                    <option value="SV">El Salvador</option>
                    <option value="SX">Sint Maarten</option>
                    <option value="SY">Syria</option>
                    <option value="SZ">Swaziland</option>
                    <option value="TC">Turks and Caicos Islands</option>
                    <option value="TD">Chad</option>
                    <option value="TF">French Southern Territories</option>
                    <option value="TG">Togo</option>
                    <option value="TH">Thailand</option>
                    <option value="TJ">Tajikistan</option>
                    <option value="TK">Tokelau</option>
                    <option value="TL">Timor-Leste</option>
                    <option value="TM">Turkmenistan</option>
                    <option value="TN">Tunisia</option>
                    <option value="TO">Tonga</option>
                    <option value="TP">East Timor</option>
                    <option value="TR">Turkey</option>
                    <option value="TT">Trinidad and Tobago</option>
                    <option value="TV">Tuvalu</option>
                    <option value="TW">Taiwan</option>
                    <option value="TZ">Tanzania</option>
                    <option value="UA">Ukraine</option>
                    <option value="UG">Uganda</option>
                    <option value="UK">United Kingdom</option>
                    <option value="US">United States</option>
                    <option value="UY">Uruguay</option>
                    <option value="UZ">Uzbekistan</option>
                    <option value="VC">Saint Vincent and the Grenadines</option>
                    <option value="VE">Venezuela</option>
                    <option value="VG">Virgin Islands</option>
                    <option value="VI">Virgin Islands (U.S.)</option>
                    <option value="VN">Vietnam</option>
                    <option value="VU">Vanuatu</option>
                    <option value="WF">Wallis and Futuna</option>
                    <option value="WS">Samoa</option>
                    <option value="YE">Yemen</option>
                    <option value="YT">Mayotte</option>
                    <option value="YU">Yugoslavia (former)</option>
                    <option value="ZA">South Africa</option>
                    <option value="ZM">Zambia</option>
                    <option value="ZR">Zaire (former)</option>
                    <option value="ZW">Zimbabwe</option>
                  </Form.Control>
                </Form.Group>
                <div className="modal_submit">
                  <Button
                    variant="primary"
                    type="button"
                    className="sub_but"
                    onClick={() => {
                      arriviaRegister();
                    }}
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default DashboardPart;
