import React, { useState, useEffect } from "react";
//import "./styles.css";
import { Row, Col, Button, Form } from "react-bootstrap";
import "./payment.css";
import "react-datepicker/dist/react-datepicker.css";
import { Logo } from "../../assets/images";

import { useParams, useHistory, Redirect } from "react-router-dom";
import axios from "../../axios/axios";

import { message } from "antd";
import LoadingOverlay from "react-loading-overlay";
const PaymentPage = () => {
  const navigate = useHistory();
  const { certiId, email } = useParams();
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [certi, setCerti] = useState({});
  const [certiAvlb, setCertiAvlb] = useState({});
  const [card, setCard] = useState({
    // number: "4242424242424242",
    // exp_month: "4",
    // exp_year: "2034",
    // cvc: "314",
    // name_on_card: "amreen test",
    number: "",
    exp_month: "",
    exp_year: "",
    cvc: "",
    name_on_card: "",
  });

  const makePayment = async (e) => {
    e.preventDefault();
    setLoading2(true);
    console.log(certiId, email);
    let data = { ...card, certiId, email };
    console.log("params pay===", data);
    await axios
      .post(
        `/payment`,
        data

        // {
        //   headers: {
        //     Authorization: token,
        //   },
        // }
      )
      .then((res) => {
        console.log("payment res...", res.data.data);
        if (res.data.status) {
          setLoading2(false);
          message.success("success");
          console.log(
            "payment res.data.receipt_url...",
            res.data.data.receipt_url
          );
          //   <Redirect to={res.data.data.receipt_url} />;
          navigate.push(`/success`);
        }
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
        navigate.push(`/failed`);
      });
  };
  const getCertiDetails = async () => {
    setLoading(true);
    let data = { certiId };
    console.log("params===", data);
    await axios
      .post(
        `/get-certificates/`,
        data

        // {
        //   headers: {
        //     Authorization: token,
        //   },
        // }
      )
      .then((res) => {
        console.log("certi res...", res.data);
        if (res.data.status) {
          setLoading(false);
          setCertiAvlb(res.data.status);
          setCerti(res.data.data);
          // message.success("success");
        } else {
          setLoading(false);
          setCertiAvlb(res.data.status);
          message.error("sorry..!!certificate not found");
          // setCerti(res.data.data);
        }
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
      });
  };

  useEffect(() => {
    getCertiDetails();
  }, []);
  return (
    <LoadingOverlay
      active={loading}
      spinner
      className="pay_loader"
      text="please Wait,Dont Refresh Page ..."
    >
      <div className="main_cont stripe_cont">
        {/* <h2>Hello, John!</h2> */}
        <LoadingOverlay
          active={loading2}
          spinner
          className="pay_loader2"
          text="Payment is being Proceeded,Dont Refresh Page ..."
        >
          <div className="paymnt_box">
            <div className="pay_logo">
              <img src={Logo} alt="" />
            </div>
            <div className="paymnt_partts">
              <div className="paymnt_inner">
                <div className="pay_card">
                  <h2>Pay with card</h2>
                  <div className="package_details">
                    <label>
                      Certificate Name:<span>{certi.certificateTitle}</span>
                    </label>
                    <label>
                      Total Amount:<span>${certi.certificatePrice}</span>
                    </label>
                  </div>
                  <div className="payment_inner_box">
                    <Form>
                      <Form.Group
                        className="mb-3 field_partts"
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Name"
                          className="login_field"
                          value={card.name_on_card}
                          onChange={(e) =>
                            setCard({
                              ...card,
                              name_on_card: e.target.value,
                            })
                          }
                        />
                      </Form.Group>
                      <Row>
                        <Col lg="12" md="12">
                          <Form.Group
                            className="mb-3 field_partts"
                            controlId="formBasicPassword"
                          >
                            <Form.Label>Card Number</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Card Number"
                              className="login_field"
                              value={card.number}
                              onChange={(e) =>
                                setCard({
                                  ...card,
                                  number: e.target.value,
                                })
                              }
                            />
                          </Form.Group>
                        </Col>
                        <Col lg="6" md="6">
                          <Form.Group
                            className="mb-3 field_partts"
                            controlId="formBasicPassword"
                          >
                            <Form.Label>Expiry Date</Form.Label>
                            {/* <Form.Control
                            type="text"
                            placeholder=""
                            className="login_field"
                          /> */}
                            <Row>
                              <Col lg="4" md="5">
                                <Form.Control
                                  type="text"
                                  placeholder="MM"
                                  className="login_field"
                                  value={card.exp_month}
                                  onChange={(e) =>
                                    setCard({
                                      ...card,
                                      exp_month: e.target.value,
                                    })
                                  }
                                />
                              </Col>
                              <Col lg="8" md="7">
                                <Form.Control
                                  type="text"
                                  placeholder="YYYY"
                                  className="login_field"
                                  value={card.exp_year}
                                  onChange={(e) =>
                                    setCard({
                                      ...card,
                                      exp_year: e.target.value,
                                    })
                                  }
                                />
                              </Col>
                            </Row>
                            <div className="select_box">
                              {/* <Form.Control
                              as="select"
                              className="stripe_select select_1"
                              value={card.exp_month}
                              onChange={(e) =>
                                setCard({
                                  ...card,
                                  exp_month: e.target.value,
                                })
                              }
                            >
                              <option value="DICTUM">MM</option>
                              <option value="CONSTANCY">Jan</option>
                              <option value="COMPLEMENT">Feb</option>
                              <option value="COMPLEMENT">Mar</option>
                              <option value="COMPLEMENT">Apr</option>
                              <option value="COMPLEMENT">May</option>
                              <option value="COMPLEMENT">June</option>
                              <option value="COMPLEMENT">Jul</option>
                              <option value="COMPLEMENT">Aug</option>
                              <option value="COMPLEMENT">Sep</option>
                              <option value="COMPLEMENT">Oct</option>
                              <option value="COMPLEMENT">Nov</option>
                              <option value="COMPLEMENT">Dec</option>
                            </Form.Control>
                            <Form.Control
                              as="select"
                              className="stripe_select select_2"
                              value={card.exp_year}
                              onChange={(e) =>
                                setCard({
                                  ...card,
                                  exp_year: e.target.value,
                                })
                              }
                            >
                              <option value="DICTUM">YYYY</option>
                              <option value="2033">2033</option>
                              <option value="2034">2034</option>
                            </Form.Control> */}
                            </div>
                          </Form.Group>
                        </Col>
                        <Col lg="6" md="6">
                          <Form.Group
                            className="mb-3 field_partts"
                            controlId="formBasicPassword"
                          >
                            <Form.Label>CVC</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="CVC"
                              className="login_field"
                              value={card.cvc}
                              onChange={(e) =>
                                setCard({
                                  ...card,
                                  cvc: e.target.value,
                                })
                              }
                            />
                            <div className="cvc_number">
                              <svg
                                className="p-CardCvcIcons-svg"
                                width="40"
                                height="40"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="var(--colorIconCardCvc)"
                                role="presentation"
                              >
                                <path
                                  opacity=".2"
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M15.337 4A5.493 5.493 0 0013 8.5c0 1.33.472 2.55 1.257 3.5H4a1 1 0 00-1 1v1a1 1 0 001 1h16a1 1 0 001-1v-.6a5.526 5.526 0 002-1.737V18a2 2 0 01-2 2H3a2 2 0 01-2-2V6a2 2 0 012-2h12.337zm6.707.293c.239.202.46.424.662.663a2.01 2.01 0 00-.662-.663z"
                                ></path>
                                <path
                                  opacity=".4"
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M13.6 6a5.477 5.477 0 00-.578 3H1V6h12.6z"
                                ></path>
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M18.5 14a5.5 5.5 0 110-11 5.5 5.5 0 010 11zm-2.184-7.779h-.621l-1.516.77v.786l1.202-.628v3.63h.943V6.22h-.008zm1.807.629c.448 0 .762.251.762.613 0 .393-.37.668-.904.668h-.235v.668h.283c.565 0 .95.282.95.691 0 .393-.377.66-.911.66-.393 0-.786-.126-1.194-.37v.786c.44.189.88.291 1.312.291 1.029 0 1.736-.526 1.736-1.288 0-.535-.33-.967-.88-1.14.472-.157.778-.573.778-1.045 0-.738-.652-1.241-1.595-1.241a3.143 3.143 0 00-1.234.267v.77c.378-.212.763-.33 1.132-.33zm3.394 1.713c.574 0 .974.338.974.778 0 .463-.4.785-.974.785-.346 0-.707-.11-1.076-.337v.809c.385.173.778.26 1.163.26.204 0 .392-.032.573-.08a4.313 4.313 0 00.644-2.262l-.015-.33a1.807 1.807 0 00-.967-.252 3 3 0 00-.448.032V6.944h1.132a4.423 4.423 0 00-.362-.723h-1.587v2.475a3.9 3.9 0 01.943-.133z"
                                ></path>
                              </svg>
                            </div>
                          </Form.Group>
                        </Col>
                      </Row>
                      <div className="pay_button">
                        {certiAvlb ? (
                          <Button
                            variant="primary"
                            type="button"
                            className="pay_but"
                            onClick={makePayment}
                          >
                            Pay
                          </Button>
                        ) : (
                          <Button
                            variant="primary"
                            type="button"
                            className="pay_but"
                          >
                            No Certificate to Pay
                          </Button>
                        )}
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </LoadingOverlay>
      </div>
    </LoadingOverlay>
  );
};

export default PaymentPage;
