import React, { useState } from "react";
import { Button } from "react-bootstrap";
import "./plan.css";
import { PaymntSuccess } from "../../assets/images";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { useEffect } from "react";
const MakePayment = (props) => {
  const paymentDetails = useSelector((state) => state.changeStep.payment);
  const user = useSelector((state) => state.changeStep.tempuser);
  const loginURL = useSelector((state) => state.changeStep.login);
  const product  = useSelector(state => state.product.details)
  const [payment, setPayment] = useState({
    // paymnt_type: paymentDetails.payment_method_details.type,
    paymnt_type: paymentDetails.payment_method_details ? paymentDetails.payment_method_details.type : "card",
    mobile: user.phone,
    email: user.email,
    amount_paid: "$" + parseInt(paymentDetails.amount) / 100,
    transaction_id: paymentDetails.id,
    receipt: paymentDetails.receipt_url ?? "",
  });
  useEffect(() => {
    props.summary(false);
  });
  return (
    <div className="sign_up_partts paymnt_parttss">
      <h3>4. Success</h3>
      <div className="paymn_success">
        <img src={PaymntSuccess} />
      </div>
      <div className="paymnt_text">
        <h2>Payment Success!</h2>
        <div className="paymnt_type">
          <div className="paymnt_inner_type">
            <div className="paymnt_inner_leftts">Payment Type</div>
            <div className="paymnt_inner_rightts">{payment.paymnt_type}</div>
          </div>

          <div className="paymnt_inner_type">
            <div className="paymnt_inner_leftts">Mobile</div>
            <div className="paymnt_inner_rightts">{payment.mobile}</div>
          </div>
          <div className="paymnt_inner_type">
            <div className="paymnt_inner_leftts">Email</div>
            <div className="paymnt_inner_rightts">{payment.email}</div>
          </div>
          <div className="paymnt_inner_type">
            <div className="paymnt_inner_leftts">Amount Paid</div>
            <div className='paymnt_inner_rightts'>${product.price}</div>
          </div>
          <div className="paymnt_inner_type">
            <div className="paymnt_inner_leftts">Transaction Id</div>
            <div className="paymnt_inner_rightts">{payment.transaction_id}</div>
          </div>
          <div className="paymnt_inner_type">
            {/* <Button
              variant="primary"
              type="button"
              onClick={() => {
                window.open(payment.receipt, "_blank");
              }}
            >
              Download Payment Reciept
            </Button> */}
            <Button
              variant="primary"
              type="button"
              onClick={() => {
                window.open(loginURL, "_blank");
              }}
            >
              Go To Dashboard
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MakePayment;
