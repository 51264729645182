import React, { useState, useEffect } from 'react';
import { Container,  Modal, Form, Button } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import './plan.css';
import ReactReadMoreReadLess from "react-read-more-read-less";
import "react-datepicker/dist/react-datepicker.css";
import AdditionalInfo from "./additional-info";
import OrderSummery from "./order-summery";
import SignUp from "./sign-up";
import Payment from "./payment";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import PaymentSuccess from "./payment-success";
import LoadingOverlay from 'react-loading-overlay';
import {payments } from '../../actions/payment.actions';
import { getTokenDetails } from '../../axios/payment.axios';
import {
    TviWLogo,
    Facebook,
    Google,
    EmailIcon,
    TripBg,
    HeaderTwoLogo,
} from "../../assets/images";
import { useSelector, useDispatch } from 'react-redux';
import { changeStep } from '../../actions/signUp.actions';

const CheckoutPart = () => {
    const [show, setShow] = useState(false);
    const [coupon,setCoupon] = useState(undefined)
    const [showSummary,setShowSummary] = useState(true)
    const [validClass, setValidClass] = useState("")
    const product  = useSelector(state => state.product.details)
    const initialPrice = product.price
    // const [formWizard,setFormWizard] = useState("signUp");
    const dispatch = useDispatch();

    const [plan,setPlan] = useState()
    const [couponLoader,setCouponLoader] = useState(false)

    const [loader,setLoader] = useState(false)

    const currentForm = useSelector(state => state.changeStep)
    const getForm = (forname) => { 
        dispatch(changeStep(forname))
    }
    const showHideLoader = (status) => {
       setLoader(status)
    }
    const changeCouponLoader = (status) => {
        setCouponLoader(status)
    }
    const handleCoupon = async (coupon) => {
        try {
            showHideLoader(true)
    
        if(!coupon){
            showHideLoader(false)
            NotificationManager.error("No Coupon given"); 
            return 0;
        }
        let details = await getTokenDetails(coupon,product.price_id)
        .catch(err => {
            showHideLoader(false)
            NotificationManager.error(err); 
        })
        let newPrice
       
        if(details){
            if(details.amount_off){
            newPrice = product.initialPrice - details.amount_off
            }
            else
            newPrice  = parseInt(product.initialPrice  -  (product.initialPrice*details.percent_off/100) )    
            dispatch(payments.setPrice(newPrice))
            showHideLoader(false)
            NotificationManager.success("Coupon applied");  
            setCoupon(details.id)
            dispatch(payments.setCoupon(details.id))
            setValidClass("valid") 
        }
        else {
            dispatch(payments.setPrice(product.initialPrice))
            dispatch(payments.setCoupon(undefined))
            setValidClass("invalid") 
        }
        } catch (error) {
            showHideLoader(false)
            NotificationManager.error(error);  
            setValidClass("invalid")  
            dispatch(payments.setCoupon(undefined))
        }
        
    }
    const handleClick = (message,status) => {
        if(status)
        NotificationManager.success(message);
        else{
        NotificationManager.error(message);   
        }
      }
    useEffect(()=>{
        getForm("signUp")        
    },[])
   
    const getWizard = () => {
        console.log(product)       
        switch (currentForm.formWizard) {
            case "signUp":
               return  (<SignUp changeWizard = {getForm} notify={handleClick} loader={showHideLoader} />)     
                    break;
            case "aInfo":
                return (<AdditionalInfo changeWizard = {getForm} notify={handleClick} loader={showHideLoader} />)
                    break;
            case "payment":
                return (<Payment changeWizard = {getForm} notify={handleClick} loader={showHideLoader} />)
                    break;
            case "final":
                return (<Payment changeWizard = {getForm} notify={handleClick} loader={showHideLoader} />)
                  
            case "success":
                return (<PaymentSuccess changeWizard = {getForm} notify={handleClick} loader={showHideLoader} summary={setShowSummary} />)
                break;
            default:
                break;
        }
    }

   
   

    return (
        <div>
            <div className='plan_parts'>
                <div className='video_bg1 plan_bg_box trip_bg' >
                    <img src={TripBg} />
                </div>
                <div className='loves_travel'>
                    <Container>
                        <div className='loves_travel_inner'>
                            <h2>EVERYONE LOVES TRAVEL.</h2>
                            <div className='tvi_w_logo'>
                                <img src={HeaderTwoLogo} alt=""/>
                            </div>
                            <div className='trip_membership'>
                                <LoadingOverlay
                                        active={loader}
                                        spinner
                                        text='Loading...'
                                    >
                                <div className='check_out_partts'>
                                    <h2>Checkout</h2>
                                    <div className='sign_order'>
                                       { 
                                       getWizard()
                                        }
                                      {
                                        showSummary && <OrderSummery validClass = {validClass} product = {product} handleApply = {handleCoupon} handleLoader = {changeCouponLoader} />
                            
                                      }
                                                                    
                                    </div>
                                </div> 
                                </LoadingOverlay>
                            </div>
                            
                        </div>
                        
                    </Container>
                    
                </div>
                
            </div>         
            </div>
           );
        };
        
        export default CheckoutPart;