import React from 'react';


export default function layout({children}) {
    return (
        <>
          <div className="content">
            {children}      
          </div>
        </>
    )
}