import React, { useState, useEffect } from "react";
//import "./styles.css";
import { Row, Col, Form, Dropdown } from "react-bootstrap";

import "./affiliate-dashboard.css";
import { ClickImg, } from "../../assets/images";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import faker from 'faker';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);



const Chart = (props) => {
  const [showMsg, setshowMsg] = useState(false)


  const copyToClipBoard = (content) => {
    var clipboard = navigator.clipboard;
    if (clipboard == undefined) {
      console.log('copy undefined');
    } else {
      clipboard.writeText(content);
      document.execCommand("copy");
      setshowMsg(true)

      setTimeout(() => {
        setshowMsg(false)
      }, 3000);
    }
  }

  const options = {
    responsive: true,
    plugins: {
      legend: {
        //position: 'top',
      },
      title: {
        display: true,
        text: 'Commission Chart',
      },
    },
  };
  let replaceUrl = props.AffiliateUrl;
  if (replaceUrl.includes('affiliate')) {
    replaceUrl = replaceUrl.replace('affiliate', 'refer');
  }

  console.log(replaceUrl, 'replaceUrl')
  return (

    <div className="chart_partts">
      <div className="chart_heading ">
        <div className="d-flex">
          <div className="border_earned shadow-sm mb-5 ml-3"><p className="p-2 pl-3 text-center">{replaceUrl}</p></div>
          <button type="button" className="copy_but" onClick={() => copyToClipBoard(replaceUrl)}>Copy</button>

        </div>
        {showMsg ? (<>
          <p className="text-center text-success"><b>Copied!</b></p>
        </>) : (<></>)}

        <div className="d-flex justify-content-between align-items-center flex-wrap">
          <div className="earned_amnt_chhhartt">
            <h2>Commission Chart</h2>
          </div>
          <div className="d-flex flex-wrap date__form">
            <Form.Group className="mx-3 md-mb-3" controlId="formBasicEmail">
              <Form.Label>From Date</Form.Label>
              <Form.Control type="date" placeholder="Enter date " name="fromDate" onChange={(event) => { props.handleChange(event) }} />
            </Form.Group>
            <Form.Group className="mx-3" controlId="formBasicEmail">
              <Form.Label>To Date</Form.Label>
              <Form.Control type="date" placeholder="Enter date" name="toDate" onChange={(event) => { props.handleChange(event) }} />
            </Form.Group>
          </div>
        </div>
      </div>
      <Bar options={options} data={props.chartData} />
    </div>
  );
};
export default Chart;