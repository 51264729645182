export const products = {
    //Live products
    "tv": [
        {
            id: "prod_NQfXs224JhSmWK",
            name: "TripValet Annual",
            price: "price_1MfoCrDfGKjcFXEHZi6bxGBI"
        },
        {
            id: "prod_NQfbaNCqLLRAJs",
            name: "TripValet Monthly",
            price: "price_1MfoGXDfGKjcFXEHLlVWcfDK"
        }
    ]

    // live products
    // "tv" :  [
    //     {
    //         id:"prod_NQfXs224JhSmWK",
    //         name: "TripValet Annual",
    //         price:"price_1MfoCrDfGKjcFXEHZi6bxGBI"
    //     },
    //     {
    //         id:"prod_NQfbaNCqLLRAJs",
    //         name: "TripValet Monthly",
    //         price:"price_1MfoGXDfGKjcFXEHLlVWcfDK"
    //     }
    // ]
}

