import React, { useState, useEffect } from "react";
//import "./styles.css";
// import {  Row, Col } from 'react-bootstrap';
import LeftpanelMember from "../../../components/leftpanel/leftpanel";
import HeaderPart from "../../../components/layout/header/header2";
import "./events.css";
// import { Trip } from "../../../assets/images";
import Moment from "react-moment";
import axios from "../../../axios/axios";
// import BodyTextEditor from "./BodyTextEditor";
import { message } from "antd";

const DashboardPart = () => {
  const [loading, setLoading] = useState(true);
  const [allCals, setAllCals] = useState([]);
  const fetchCals = async () => {
    let data = { portal: "TripValet" };
    // console.log("page row chng", pageNew, pagination.limit);
    await axios
      .post(
        `/selected-calendar`,
        data

        // {
        //   headers: {
        //     Authorization: token,
        //   },
        // }
      )
      .then((res) => {
        console.log("get-calendar res...", res.data);
        if (res.data.status) {
          setAllCals(res.data.data);
          message.success("Calendars fetched");
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  useEffect(() => {
    fetchCals();
  }, [loading]);
  return (
    <div>
      <HeaderPart />
      <div className="main_cont">
        <LeftpanelMember />
        <div className="right_cont">
          <div className="right_banner_banner_cnt">
            <div className="event_heading">
              <h2>Event Gallery</h2>
            </div>
            <div className="event_image_group">
              {allCals.length > 0
                ? allCals.map((cal) => {
                    return (
                      <div className="event_image_inner_group">
                        <div className="event_image_inner_box">
                          <a href={cal.calendarImg} target="_blank">
                            <img src={cal.calendarImg} />
                            <p>
                              <Moment format="DD-MMM-YYYY">{cal.date}</Moment>
                            </p>
                            <p>{cal.calendarName}</p>
                          </a>
                        </div>
                      </div>
                    );
                  })
                : "No Calendar of Events Uploaded"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardPart;
