const initialState = {
    product_id: "prod_NQflUMfj9Gcecq",
    name: "TripValet Monthly",
    price: 49.97,
    initialPrice: 49.97,
    coupon: undefined
}
const product = (state = initialState, action) => {
    switch (action.type) {
        case "SET":
            return {
                details: action.payload
            }
        case "UPDATE":
            return {

                details: {
                    ...state.details,
                    price: action.payload
                }
            }
        case "SETCOUPON":
            return {
                details: {
                    ...state.details,
                    coupon: action.payload
                }
            }
        case "SETCODE":
            return {
                details: {
                    ...state.details,
                    affiliate: action.payload
                }
            }
        default:
            return {
                ...state
            }

    }
}
export default product