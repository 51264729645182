import React, { useState } from 'react';
import { Container, Modal, Form, Button } from 'react-bootstrap';
import './plan.css';
import { useSelector, useDispatch } from 'react-redux';
import { DownArrow } from "../../assets/images";
const OrderSummery = (props) => {
    const product = useSelector(state => state.product.details)
    const [visible, setVisible] = useState(false);
    const [coupon, setCoupon] = useState("")
    console.log(product.priceObject.recurring.interval, 'product')
    return (
        <div className='order_summery'>
            <div className='ord_inner'>
                <h3>Order summary</h3>
                <div className='plan_summ'>
                    <div className='plan_left'>Plan</div>
                    {/* <div className='plan_right'>TRIPVALET MONTHLY</div> */}
                    <div className='plan_right'>{product.name}</div>
                </div>
                <div className='plan_summ'>
                    <div className='plan_left'>Duration</div>
                    <div className='plan_right'>Until canceled</div>
                </div>

                <div className='plan_summ'>
                    <div className='plan_left'>Free trial</div>
                    <div className='plan_right'>7 days</div>
                </div>
                <div className='coupon_code'><button type='button' className='coupon_but' onClick={() => setVisible(true)}>Enter a coupon code <img src={DownArrow} /></button>
                    {visible && <div><Form.Group className={"mb-3 " + props.validClass} controlId="exampleForm.ControlInput1">
                        <Form.Control type="text" placeholder="Enter a coupon code" onChange={(event) => { setCoupon(event.target.value) }} />
                    </Form.Group><button type="submit" disable className='apply_but' onClick={() => { props.handleApply(coupon) }}>Apply</button></div>}
                </div>
                <div className='total_amnt'>
                    <div className='text_l'>Total</div>
                    {/* <div className='text_r'>$49<span>Every Month</span></div> */}
                    <div className='text_r'>${product.price}<span>Every {(product.priceObject.recurring.interval == 'year') ? 'Year' : 'Month'}</span></div>
                </div>
                <div className='freetrial'>
                    After the free trial, you will be charged {(product.priceObject.recurring.interval == 'year') ? 'yearly' : 'monthly'} until canceled.
                </div>
            </div>
            <div className='secure_check'>
                Secure Checkout
            </div>
        </div>
    );
};

export default OrderSummery;