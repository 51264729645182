import React from "react";
import PlanFooter from "./PlanFooter";

export default function PlanLayout({ children }) {
  return (
    <>
      <div className="content">{children}</div>
      <PlanFooter />
    </>
  );
}
