import React from 'react';
//import "./styles.css";
// import {  Row, Col } from 'react-bootstrap';
// import { Document,Page } from 'react-pdf/dist/esm/entry.webpack';
import Leftpanel from "../../components/leftpanel/leftpanel";
import HeaderPartTwo from "../../components/layout/header/header2";
import './coming-soon.css';
// import {
//     Trip,
//     PDFFile,
// } from "../../../../assets/images";

const ComingSoon = () => {
    return (
            <div>
                <HeaderPartTwo />
                <div className='main_cont'>
                    <Leftpanel />
                    <div className='coming_soon_page'>
                        <div className='coming_heading'>
                            <h2>Coming Soon</h2>
                        </div>
                    </div>
                </div>
            </div>
            );
        };

export default ComingSoon;