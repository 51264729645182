import React, { Component } from "react";
import { Button, Form } from "react-bootstrap";
import axios from "../../axios/axios";
import LoadingOverlay from "react-loading-overlay";
import { message } from "antd";
import { LoginBanner, Logo } from "../../assets/images";
export default class ForgetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,

      email: "",
      password: "",
      con_pass: "",

      error: {},
    };
  }

  handleOnChnage = (e) => {
    let { name, value } = e.target;

    let { error, password, con_pass } = this.state;

    this.setState({ [name]: value });

    if (name === "password") {
      if (con_pass) {
        if (value !== con_pass) {
          error.con_pass = "Confirm Password not match";
        } else {
          error.con_pass = "";
        }
      }
    }

    if (name === "con_pass") {
      if (value !== password) {
        error.con_pass = "Confirm Password not match";
      } else {
        error.con_pass = "";
      }
    }

    this.setState({ error });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    let { token } = this.props.match.params;

    let { password, con_pass, error } = this.state;

    if (password !== con_pass) {
      error.con_pass = "Confirm Password not match";
      this.setState({ error });
      return;
    } else {
      error.con_pass = "";
      this.setState({ error });
    }

    const formData = {
      password,
      token,
    };
    this.setState({ isLoading: true });
    axios
      .post("/user/update-user-password-token", formData)
      .then((res) => {
        this.setState({ isLoading: false });
        if (res.data.status) {
          message.success("Password updated successfully");
          this.props.history.push({
            pathname: "/login",
          });
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        console.error(err);
        message.error("Something went wrong!!!!");
      });
  };

  render() {
    let { error, isLoading } = this.state;

    return (
      <LoadingOverlay active={isLoading} spinner text="Loading ...">
        <div className="main_cont">
          <div className="login_page">
            <div className="login_page_left">
              <img alt="TV" src={LoginBanner} className="img-fluid" />
            </div>
            <div className="login_page_right">
              <div className="login_page_right_inner">
                <div className="login_inner_logo">
                  <img alt="TV" src={Logo} />
                </div>
                <div className="login_inner_bottom">
                  <Form onSubmit={this.handleSubmit}>
                    {/* <Form.Group className="mb-4" controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    className="login_field"
                    placeholder="Enter email"
                    value={this.state.email}
                    onChange={this.handleOnChnage}
                    required
                  />
                </Form.Group> */}

                    <Form.Group className="mb-4" controlId="formBasicPassword">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type="password"
                        className="login_field"
                        placeholder="Password"
                        required
                        name="password"
                        value={this.state.password}
                        onChange={this.handleOnChnage}
                      />
                    </Form.Group>

                    <Form.Group
                      className="mb-4"
                      controlId="formBasicConfirmPassword"
                    >
                      <Form.Label>Confirm Password</Form.Label>
                      <Form.Control
                        type="password"
                        className="login_field"
                        placeholder="Password"
                        required
                        name="con_pass"
                        value={this.state.con_pass}
                        onChange={this.handleOnChnage}
                      />
                      <p className="text-danger">{error.con_pass}</p>
                    </Form.Group>
                    <div className="login_submit">
                      <Button
                        variant="primary"
                        type="submit"
                        className="sub_log_but"
                      >
                        Submit
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}
