import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.css";
// require('dotenv').config()
import "bootstrap/dist/css/bootstrap.min.css";
// import { DotenvConfigOptions } from 'dotenv';

import Layout from "./components/layout/layout";
import DashboardPage from "./pages/dashboard/dashboard";
import EventPage from "./pages/dashboard/calendar-of-events/events";
import ComingSoon from "./pages/coming-soon/coming-soon";
import LoginPage from "./pages/login/login";
import PaymentPage from "./pages/payment/payment";
import PlanPart from "./pages/signup/plan";
import CheckoutPart from "./pages/signup/checkout";
import LogoutPage from "./pages/logout/logout";
// import PaymentPage from "./pages/payment/payment";
import AffiliateDashboard from "./pages/affiliate-dashboard/affiliate-dashboard";
import ForgetPassword from "./pages/login/ForgetPassword";
import PlanLayout from "./components/layout/PlanLayout";
import MyAccount from "./pages/dashboard/my-account/my-account";
import UpdatePlanPayment from "./pages/signup/update-plan-payment";

function RouteWrapper({ component: Component, layout: Layout, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout {...props}>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

function PlanRouteWrapper({ component: Component, layout: Layout, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout {...props}>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <RouteWrapper
            path="/"
            component={DashboardPage}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/login"
            component={LoginPage}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/forget-password/:token"
            component={ForgetPassword}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/my-account"
            component={MyAccount}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/update-plan-payment"
            component={UpdatePlanPayment}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/events"
            component={EventPage}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/shopping-entertainment"
            component={ComingSoon}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/payment/:email/:certiId"
            component={PaymentPage}
            layout={Layout}
            exact
          />

          {/* <RouteWrapper path="/" component={DashboardMemberPage} layout={Layout} exact/> */}
          {/* <RouteWrapper path="/travel-certificates" component={TravelCertificates} layout={Layout} exact/> */}
          {/*<RouteWrapper path="/add-link" component={AddLink} layout={Layout} exact/>
          <RouteWrapper path="/add-certificate" component={AddCertificate} layout={Layout} exact/>
          <RouteWrapper path="/user-details" component={UserDetails} layout={Layout} exact/>
          <RouteWrapper path="/transaction-details" component={TransactionDetails} layout={Layout} exact/>   
          <RouteWrapper path="/bulk-upload" component={BulkUploadCertificates} layout={Layout} exact/>    
          <RouteWrapper path="/plan" component={PlanPart} layout={Layout} exact/>
          <RouteWrapper path="/check-out" component={CheckoutPart} layout={Layout} exact/>
          <RouteWrapper path="/add-member" component={AddMember} layout={Layout} exact/>
          <RouteWrapper path="/bulk-upload-member" component={BulkUploadMember} layout={Layout} exact/>
           */}
          {/* <RouteWrapper path="/payment" component={PaymentPage} layout={Layout} exact/> */}
          <PlanRouteWrapper
            path="/plan"
            component={PlanPart}
            layout={PlanLayout}
            exact
          />

          <RouteWrapper
            path="/check-out"
            component={CheckoutPart}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/logout"
            component={LogoutPage}
            layout={Layout}
            exact
          />
          {/* <PlanRouteWrapper
            path="/affiliate/:stype?/:code"
            component={PlanPart}
            layout={Layout}
          /> */}
          <PlanRouteWrapper
            path="/refer/:stype?/:code"
            component={PlanPart}
            layout={Layout}
          />
          <RouteWrapper
            path="/affiliate-dashboard"
            component={AffiliateDashboard}
            layout={Layout}
            exact
          />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
