import React, { useState, useEffect } from "react";
//import "./styles.css";
import { Row, Col, Form } from "react-bootstrap";
import Wizard from "./wizard";
import ChartPartts from "./chart";
import HeaderPartTwo from "../../components/layout/header/header2";
import Leftpanel from "../../components/leftpanel/leftpanel";
import DataTable from "react-data-table-component";
import AffiliateTable from "./affiliate-table";
import "./affiliate-dashboard.css";
import { commissionBarChart, commissionStats, getAffiliateChildren, getCommission } from "../../axios/commission.axios";
import { Button, Modal } from "react-bootstrap";
import CommissionTable from "./commission-table";

const AffiliateDashboard = () => {

  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
        },
        fontSize: "17px",
        color: "#004aad",
      },
    },

    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
        },
        fontSize: "16px",
      },
    },
  };
  const columnsChildren = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,

    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,

    },
    {
      name: "Phone",
      selector: (row) => row.phone ?? "",
      sortable: true,

    },
    {
      name: "Date of Enrollment",
      selector: (row) => new Date(row.createdAt).toLocaleDateString(),
      sortable: true,

    }
  ];
  const [user, setUser] = useState(localStorage.getItem("userDetails") ? JSON.parse(localStorage.getItem("userDetails")) : null)
  const [AffiliateUrl, SetAffiliateUrl] = useState("")
  const [afUsers, setAfUsers] = useState([])
  const [BarChart, SetBarChart] = useState({
    "labels": ["19/3/2023", "20/3/2023", "21/3/2023", "22/3/2023", "23/3/2023", "24/3/2023", "25/3/2023"],
    "datasets": [
      {
        "label": "Commission earned ($)",
        "data": [0, 0, 0, 0, 0, 0, 0],
        "backgroundColor": "#004aad"
      }
    ]
  })
  const [Children, setChildren] = useState([])
  const [DateFilter, setDateFilter] = useState({
    fromDate: null,
    toDate: null
  })
  const [show, setShow] = useState(false);
  const [Commissions, setCommissions] = useState([])



  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const changeDate = (event) => {
    setDateFilter({
      ...DateFilter,
      [event.target.name]: event.target.value
    })

  }
  const getCommissionReport = async () => {
    let userDetails = JSON.parse(localStorage.getItem("userDetails"));
    let commissions = await getCommission(userDetails.id)
    commissions = commissions.length > 0 ? commissions.map(commission => { return !commission.isRedeemed ? { ...commission, actions: <Button id={commission.id} onClick={(event) => { getAffiliateUserChildren(event) }}>Redeem</Button> } : "Redeemed" }) : []

    setCommissions(commissions)
  }
  const getAffiliateUserChildren = async (event) => {
    let userId = event.target.id
    let children = await getAffiliateChildren(userId)
    if (children.length > 0) {
      setChildren(children)
    } else {
      setChildren([]);
    }
    handleShow()
  }
  const [Widgets, setWidgets] = useState({
    signup: {
      value: "0",
      sign: "",
      subHeader: "ENROLLMENTS"
    },
    click: {
      value: "0",
      sign: "",
      subHeader: "CLICKS"
    },
    commission: {
      value: "0",
      sign: "$",
      subHeader: "WEEKLY COMMISSIONS EARNED"
    },
    refund: {
      value: "0",
      sign: "$",
      subHeader: "LIFETIME COMMISSIONS EARNED"
    }
  })
  const getBarChartData = async () => {
    let userDetails = JSON.parse(localStorage.getItem("userDetails"));
    commissionBarChart(userDetails.id, DateFilter)
      .then((response) => {

        SetBarChart(response)

      })
  }

  const getWidgetData = () => {
    let weekly_commissions_earned = 0;
    let lifetime_commissions_earned = 0;
    let clicks = 0;
    let enrollments = 0;
    let userDetails = JSON.parse(localStorage.getItem("userDetails"));
    commissionStats(userDetails.id)
      .then((statsdata) => {
        if (statsdata.affiliateStats) {
          enrollments = statsdata.affiliateStats.enrollments
          clicks = statsdata.affiliateStats.totalHits
        }
        if (statsdata.commissionStats.length > 0) {
          weekly_commissions_earned = statsdata.commissionStats[0].weeklyCommission
          lifetime_commissions_earned = statsdata.commissionStats[0].totalCommission
        }
        if (statsdata.affiliateStats.users) {
          let tableData = statsdata.affiliateStats.users.length > 0 ? statsdata.affiliateStats.users.map(user => { return { ...user, actions: <Button id={user.id} onClick={(event) => { getAffiliateUserChildren(event) }}>Tier 2</Button> } }) : []

          setAfUsers(tableData)
        }

        let widgetData = {
          signup: {
            value: enrollments,
            sign: "",
            subHeader: "ENROLLMENTS"
          },
          click: {
            value: clicks,
            sign: "",
            subHeader: "CLICKS"
          },
          commission: {
            value: weekly_commissions_earned,
            sign: "$",
            subHeader: "WEEKLY COMMISSIONS EARNED"
          },
          refund: {
            value: lifetime_commissions_earned,
            sign: "$",
            subHeader: "LIFETIME COMMISSIONS EARNED"
          }
        }
        setWidgets(widgetData)
        SetAffiliateUrl(statsdata.affiliateUrl)

      })


  }

  useEffect(() => {

    getBarChartData()
  }, [DateFilter])

  useEffect(() => {
    getWidgetData()
    getCommissionReport()
  }, [])


  return (
    <div> <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Body>
        <div className="table-responsive">
          <DataTable
            columns={columnsChildren}
            data={Children}
            //pagination
            striped
            responsive
            customStyles={customStyles}
            subHeaderAlign="right"
            subHeaderWrap
            //paginationDefaultPage={page}
            pagination
          //paginationTotalRows={pagination.totalRows}
          //paginationPerPage={limit}
          //paginationRowsPerPageOptions={[10, 15, 20, 40]}
          //onChangeRowsPerPage={handlePerRowsChange}
          //onChangePage={changePage}
          //progressComponent={<CustomLoader />}
          //noDataComponent={<EmptyDataLoader />}
          />
        </div>
      </Modal.Body>

    </Modal>
      <HeaderPartTwo userDetails={user} />
      <div className='main_cont'>
        <Leftpanel />
        <section className="right_cont">
          <div className="right_banner_banner_cnt">
            <Wizard Widgets={Widgets} AffiliateUrl={AffiliateUrl} />
            <ChartPartts AffiliateUrl={AffiliateUrl} chartData={BarChart} handleChange={changeDate} />
            <AffiliateTable users={afUsers} handleChange={getAffiliateUserChildren} children={Children} />
            <CommissionTable commissionReport={Commissions} />
          </div>
        </section>
      </div>
    </div>
  );
};
export default AffiliateDashboard;